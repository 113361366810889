import 'bootstrap';
import 'jquery-lazy';
import 'assets/styles/app.scss';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();

			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	$(document).on( 'click', 'button.plus, button.minus', function(e) {
		e.preventDefault();
		var qty = $( this ).parent( '.quantity' ).find( '.qty' );
		var val = parseFloat(qty.val());
		var max = parseFloat(qty.attr( 'max' ));
		var min = parseFloat(qty.attr( 'min' ));
		var step = parseFloat(qty.attr( 'step' ));
		if ( $( this ).is( '.plus' ) ) {
			if ( max && ( max <= val ) ) {
				qty.val( max ).change();
			} else {
				qty.val( val + step ).change();
			}
		} else {
			if ( min && ( min >= val ) ) {
				qty.val( min ).change();
			} else if ( val > 1 ) {
				qty.val( val - step ).change();
			}
		}
	});

	$(document.body).trigger('wc_fragment_refresh');
	$('[data-bs-toggle="tooltip"]').tooltip()
} );
